import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';
import FormInput from '../../../../commonComponents/forms/FormInput';
import Form from '../../../../commonComponents/forms/Form';
import Notifications from '../../../../services/notifications';

function ConnectorPromail(props) {
  const { t, _csrf, onChange, promailService, isAuthenticated } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const method = !isAuthenticated ? 'put' : 'delete';
      const endpoint = '/api/auth/organization/services/promail';
      let params = {};
      const config = {
        headers: {
          'Accept-Version': '1.0.0',
          'X-Gamification-API': 'Auth',
          'X-CSRF-Token': _csrf,
          _csrf,
        },
      };

      if (!isAuthenticated) {
        params = {
          serviceData: {
            config: {
              key: promailService.key,
              privateKey: promailService.privateKey,
              baseOrderNumber: promailService.baseOrderNumber,
              email: promailService.email,
            },
          },
        };
      }

      await axios[method](
        endpoint,
        !isAuthenticated ? params : config,
        !isAuthenticated ? config : undefined,
      );

      Notifications.showNotificationSuccess(
        t('success'),
        !isAuthenticated
          ? t('authenticated.success')
          : t('authentication.remove.successfully'),
      );
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      Notifications.showErrorFromRequest(
        err,
        !isAuthenticated
          ? 'authenticated.fail'
          : 'authentication.remove.failed',
      );
    }
  }, [isAuthenticated, promailService, setIsLoading, t]);

  const buttonCx = cx(
    'btn',
    'btn-dynamic',
    { 'button-authenticate': !isAuthenticated },
    { 'button-remove-auth': isAuthenticated },
    { 'm-loader': isLoading },
    { 'm-loader--right': isLoading },
    { 'm-loader--light': isLoading },
  );

  return (
    <div id="promailServiceContainer">
      <Form
        validationRules={{
          promailKey: { required: true },
          promailPrivateKey: { required: true },
          promailBaseOrderNumber: { required: true },
          promailEmail: { required: true },
        }}
        submitMethod={handleClick}
        id="promailForm"
      >
        {submitHandler => (
          <>
            <h4 className="title">{t('service_type_promail')}</h4>
            <FormInput
              id="promailKey"
              name="promailKey"
              label={t('service.key')}
              onChange={onChange('key')}
              value={promailService.key}
              type="password2"
              containerClassName="apiKey-inputContainer"
              inputProps={{
                autoComplete: 'off',
              }}
            />
            <FormInput
              id="promailPrivateKey"
              name="promailPrivateKey"
              label={t('service.privateKey')}
              onChange={onChange('privateKey')}
              value={promailService.privateKey}
              type="password2"
              containerClassName="apiKey-inputContainer"
              disabled={isAuthenticated}
              inputProps={{
                showEyeIcon: !isAuthenticated,
                autocomplete: 'off',
              }}
            />
            <FormInput
              id="promailbaseOrderNumber"
              name="promailbaseOrderNumber"
              label={t('service.promail.baseOrderNumber')}
              onChange={onChange('baseOrderNumber')}
              value={promailService.baseOrderNumber}
              type="text"
              containerClassName="apiKey-inputContainer"
              inputProps={{
                autocomplete: 'off',
              }}
            />
            <FormInput
              id="promailEmail"
              name="promailEmail"
              label={t('email')}
              onChange={onChange('email')}
              value={promailService.email}
              type="email"
              containerClassName="apiKey-inputContainer"
              inputProps={{
                autoComplete: 'off',
              }}
            />
            <div className="button-container">
              <button
                type="button"
                onClick={submitHandler}
                className={buttonCx}
                disabled={isLoading}
              >
                {!isAuthenticated
                  ? t('authenticate')
                  : t('authentication.remove')}
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

ConnectorPromail.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  promailService: PropTypes.shape({
    key: PropTypes.string,
    privateKey: PropTypes.string,
    baseOrderNumber: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default ConnectorPromail;
