import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import ConnectorPromail from '../../sections/settings/configurations/connectors/ConnectorPromail';
import organizationProp from '../../commonComponents/propTypes/organizationProp';

const ConnectorPromailWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { organization, _csrf } = props;
  const { promail: promailService } = organization?.services || {};

  const [promail, setPromail] = useState(promailService?.config || {});
  useEffect(() => propagateChanges(promail), [promail]);

  const handleChanges = useCallback(
    field => value => {
      setPromail(prevMBE => ({ ...prevMBE, [field]: value }));
    },
    [setPromail],
  );

  useImperativeHandle(ref, () => ({
    getValue: () => promail,
    setValue: newPromail => setPromail(newPromail),
  }));

  return (
    <>
      <ConnectorPromail
        _csrf={_csrf}
        promailService={promail}
        onChange={handleChanges}
        isAuthenticated={promailService?.config?.configured}
        {...childProps}
      />
    </>
  );
});

ConnectorPromailWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
};

ConnectorPromailWebHandler.defaultProps = {};

window.ConnectorPromail = class ConnectorPromailRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'ConnectorPromail container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <ConnectorPromailWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
